<template>
    <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" :label="$t('manager_details.name')" width="200" sortable>
            <template slot-scope="scope">
                <router-link :to="`/employees/${scope.row.uuid}/information`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.name }} {{ scope.row.surname }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column prop="address.street" :label="$t('manager_details.street')" sortable />
        <el-table-column prop="address.city" :label="$t('manager_details.city')" sortable />
        <el-table-column prop="email" :label="$t('manager_details.email')" sortable show-overflow-tooltip="" />
        <el-table-column prop="telephone" :label="$t('manager_details.telephone')" width="100" />
    </el-table>
</template>
<script>
export default {
    props: {
        tableData: {
            type:    Array,
            default: () => [],
        },
    },
};
</script>
